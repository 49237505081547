.ngchip {
  --color: var(--color-label);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 0.375rem;
  border-color: var(--color);
  color: var(--color);
  background-color: var(--background-color);
  transition: var(--transition);
  width: fit-content;

  line-height: var(--line-height-medium);
  letter-spacing: var(--letter-spacing);

  &.normal {
    padding: 8px 10px;
    font-size: 16px;
  }
  &.small {
    padding: 5px 10px;
    font-size: 14px;
  }

  &.success {
    --background-color: var(--color-alert-success-drop);
    --color: var(--color-alert-success-label);
  }
  &.error {
    --background-color: var(--color-alert-error-drop);
    --color: var(--color-alert-error-label);
  }
  &.warning {
    --background-color: var(--color-alert-warning-drop);
    --color: var(--color-alert-warning-label);
  }
  &.info {
    --background-color: var(--color-alert-info-drop);
    --color: var(--color-alert-info-label);
  }
}
