.logs-live {
  color: var(--color-default);
  border-radius: var(--border-radius);
}
.logs-live:focus {
  outline: none;
}

.logs-live-active {
  color: var(--color-default);
  border-color: var(--color-default);
}
.logs-live-active:hover {
  color: var(--color-action) !important;
  border-color: var(--color-action) !important;
}

.logs-live-disabled {
  color: var(--color-placeholder);
  border-color: var(--color-placeholder);
  cursor: not-allowed;
}

.live-stop {
  color: var(--color-danger);
  border-color: var(--color-danger);
}

.live-stop:hover {
  background-color: var(--color-red-50);
}

.dark .live-stop:hover {
  background-color: var(--color-red-300);
  color: var(--color-white);
}

.live-restart {
  color: var(--color-danger);
  border-color: var(--color-danger);
}

.live-restart .restart:hover,
.live-restart .stop:hover {
  background-color: var(--color-red-50);
}

.dark .live-restart .restart:hover,
.dark .live-restart .stop:hover {
  color: var(--color-white);
  border-color: var(--color-danger);
}
