.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  /*To match it with refresh icon*/
  margin-left: -2px;
  padding-left: 4px;
  padding-top: 2px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div {
  border: 2px solid #686868;
  border-color: #686868 #c7c7c7 #c7c7c7 #c7c7c7;
}
.lds-ring div.danger {
  border: 2px solid #fff;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
