.ngalert {
  --color: var(--color-label);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-left: 3px solid currentColor;
  border-radius: 0.375rem;
  border-color: var(--color);
  color: var(--color);
  background-color: var(--background-color);
  transition: var(--transition);
  width: fit-content;
  min-width: 450px;
  max-width: 600px;

  &.normal {
    padding: 8px 10px;
    font-size: 16px;
  }

  &.small {
    padding: 5px 10px;
    font-size: 14px;

    .content {
      gap: 0.188rem;
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    font-style: normal;
    line-height: var(--line-height-medium);
    letter-spacing: var(--letter-spacing);

    .close {
      cursor: pointer;
      border-radius: 20%;
      margin-top: auto;
      margin-bottom: auto;
    }

    .title {
      font-weight: var(--semibold);
    }
  }

  &.success {
    --background-color: var(--color-alert-success-drop);
    --color: var(--color-alert-success-label);
  }
  &.error {
    --background-color: var(--color-alert-error-drop);
    --color: var(--color-alert-error-label);
  }
  &.warning {
    --background-color: var(--color-alert-warning-drop);
    --color: var(--color-alert-warning-label);
  }
  &.info {
    --background-color: var(--color-alert-info-drop);
    --color: var(--color-alert-info-label);
  }
}
