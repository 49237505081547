:root {
  --reach-tooltip: 1;
  --mono-font: "input-mono-compressed", monospace;
}

#sentry-feedback {
  z-index: 9998;

  --foreground: var(--color-label);
  --font-family: "proxima-nova", sans-serif;
  --box-shadow: none;
  --border: 1px solid var(--color-border);
  --background: var(--color-drop);

  /* Report Button */
  --actor-border-radius: var(--border-radius);
  --actor-hover-background: var(--color-gray-100);

  /* Submit Button */
  --button-primary-background: var(--color-blue-300);
  --button-primary-hover-background: var(--color-blue-400);

  /* Secondary Button */
  --button-hover-background: var(--color-gray-50);

  /* Dialog */
  --dialog-border-radius: var(--border-radius);

  /* Input */
  --input-focus-outline: 0;
  --input-border-radius: var(--border-radius);
}

.dark #sentry-feedback {
  /* Report Button */
  --actor-hover-background: var(--color-gray-1400);

  /* Submit Button */
  --button-primary-background: var(--color-blue-600);
  --button-primary-hover-background: var(--color-blue-500);

  /* Secondary Button */
  --button-hover-background: var(--color-gray-1300);
}

#affix-root {
  padding-left: 220px;
  padding-right: 300px;
}

#affix-root-disallowed-email {
  padding-left: 220px;
  padding-right: 300px;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
}

* {
  box-sizing: border-box;
}

.proxima-nova:not(.font-mono) {
  font-family: "proxima-nova", sans-serif;
}

.font-mono {
  font-family: var(--mono-font);
  letter-spacing: -0.3px;
}

.cm-editor * {
  font-family: var(--mono-font) !important;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  color: white;
  width: 80px;
  height: 42px;
}

.btn-green {
  background-color: rgb(21, 205, 114);
}

.btn-green:hover {
  background-color: #0cb863;
}

.btn-red {
  background-color: #cd1515;
}

.btn-red:hover {
  background-color: #b41010;
}

.btn-blue {
  background-color: #0069ff;
}

.btn-blue:hover {
  background-color: #0052ff;
}

.dark .btn-blue {
  background-color: var(--color-blue-600);
}

.dark .btn-blue:hover {
  background-color: var(--color-blue-500);
}

.btn > span {
  font-size: 14.4px;
}

.btn-icon {
  width: 94px;
}

.hover-blue:hover {
  color: rgb(0, 105, 255) !important;
}

.hover-green:hover {
  color: #0cb863 !important;
}

.btn-danger {
  color: #ed4f32;
  border: 1px solid #ed4f32;
  border-radius: 3px;
  background-color: white;
  transition: all 0.2s;
}

.btn-danger:hover {
  color: white;
  background-color: #ed4f32;
}

.after-chevron-down {
  padding-right: 15px;
}

.after-chevron-down::after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

#genericCreate {
  position: fixed;
  z-index: 100;
}

.detail-nav__subLink {
  padding-left: 20px;
  width: 160px;
}

#update-notification {
  display: flex;
  transition: all 0.5s ease-in;
}

.data-reach-tooltip {
  z-index: 9999;
  /* pointer-events: none; */
  position: absolute;
  padding: 0.5rem 1rem;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  word-break: break-word;
  font-size: 85%;
  background: var(--color-drop);
  color: var(--color-label);
  max-width: 800px;
  border-radius: 4px;
}

.dark .data-reach-tooltip {
  background: var(--color-drop-secondary);
}

.copy-tooltip .data-reach-tooltip {
  padding: 0.25rem 0.25rem;
  box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.1);
  font-size: 85%;
  background: #fff;
  color: #686868;
  border: 0;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }
}

.fast-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Logs start stop restart */

.live-stop > .fast-pulse {
  background-color: var(--color-red-400);
}

.dark .live-stop:hover > .fast-pulse {
  background-color: white;
}

/* Restart */
.live-restart > .restart > .fast-pulse,
.live-restart > .stop > span {
  background-color: var(--color-red-400);
}

.live-restart > .restart > .resume-icon {
  stroke: transparent;
  fill: var(--color-red-400);
}

.live-restart > .stop:hover,
.live-restart > .restart:hover {
  background-color: var(--danger-100);
}

.live-restart > .restart:hover > .fast-pulse,
.live-restart > .stop:hover > span {
  background-color: var(--color-red-400);
}

.dark .live-restart > .restart:hover,
.dark .live-restart > .stop:hover {
  background-color: var(--color-red-400);
  color: white;
}

.dark .live-restart > .restart:hover > .fast-pulse,
.dark .live-restart > .stop:hover > span {
  background-color: white;
}

.dark .live-restart > .restart:hover > .resume-icon {
  stroke: transparent;
  fill: white;
}

.dark .live-restart > .restart:hover > .resume-icon {
  stroke: transparent;
  fill: white;
}

.hidden-popover {
  display: none !important;
}

#getStarted {
  left: 90px;
}

.ping-pong {
  animation: pingponganim 0.5s ease-in-out 1s infinite alternate;
}

@keyframes pingponganim {
  from {
    left: 90px;
  }

  to {
    left: 105px;
  }
}

#debug {
  position: fixed;
  z-index: 1000;
  left: 290px;
  bottom: 20px;
}

.dropdown {
  min-width: 400px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* 16px */
  padding: 1rem /* 16px */ 1.25rem /* 20px */;
  background-color: var(--color-drop);
  border-color: var(--color-border);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.dropdown-title {
  color: var(--color-label);
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: var(--letter-spacing);
}

.dropdown-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* 12px */
}

.dropdown-item label {
  color: var(--color-label) !important;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: var(--letter-spacing);
}

.dropdown-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1.25rem; /* 20px */
}

.dark .recharts-wrapper,
.dark .recharts-cartesian-axis * {
  fill: var(--color-default);
}

/* Customize scrollbar */

/* Outer wrapper*/

/* width */
#terminal-parent::-webkit-scrollbar {
  width: 17px;
}

/* Track */
#terminal-parent::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#terminal-parent::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
#terminal-parent::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* inner terminal */

/* width */
.xterm-viewport::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.xterm-viewport::-webkit-scrollbar-track {
  background: #222;
}

/* Handle */
.xterm-viewport::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
.xterm-viewport::-webkit-scrollbar-thumb:hover {
  background: #444;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-drop-autofill) inset !important;
  -webkit-text-fill-color: var(--normal-autofill) !important;
}

body > iframe:last-of-type {
  pointer-events: none !important;
  display: none !important;
}

.dark .testimonial {
  background-color: #2f3e51;
}

.feather-icon {
  width: 16px;
  height: 16px;
}

.diff-container tbody tr:first-of-type {
  display: none;
}

.log-odd-bg {
  background-color: rgb(255, 255, 255);
}
.log-even-bg {
  background-color: rgb(250, 250, 250);
}

.dark .log-odd-bg {
  background-color: rgb(35, 35, 35);
}
.dark .log-even-bg {
  background-color: rgb(30, 30, 30);
}

#drag-placeholder {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

[data-testid="user-profile"] {
  border-color: #e9e9e9;
}

[data-testid="user-profile"]:hover {
  background-color: #cbcbcb;
}

.dark [data-testid="user-profile"] {
  border-color: #323232;
}

.dark [data-testid="user-profile"]:hover {
  background-color: #282828;
}

.expression-editor .cm-theme {
  height: 138px;
  max-height: 138px;
  overflow: auto;
}

/* Generic Dropdown Animation START */
/* Need to add and remove the class with 100ms timeout */

.useFadeInOutAnim {
  transform-origin: left center;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.useFadeInOutAnim.fadeIn {
  animation: fadein 0.1s;
  animation-fill-mode: forwards;
}

.useFadeInOutAnim.fadeOut {
  animation: fadeout 0.1s;
  animation-fill-mode: forwards;
  box-shadow: 0 0 #0000;
}

.useFadeInOutAnim.open {
  pointer-events: all;
}

.useFadeInOutAnim.closed {
  pointer-events: none;
}

.useFadeInOutAnim.shrink {
  opacity: 0;
  transform: scaleX(0.01);
}

/*** Key Frames ***/
@keyframes fadein {
  0% {
    opacity: 0;
    transform: scaleX(0.01);
  }
  1% {
    opacity: 1;
    transform: scaleX(0.1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  1% {
    opacity: 1;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(0.01);
  }
}

/* Generic Dropdown Animation END */

/* Modal */
.modal-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem; /* 16px */
}

.usage-blur {
  filter: blur(5px);
}

.cm-focused {
  outline: none !important;
}

a,
a:hover {
  color: unset;
}

.dark .container-image-icon {
  fill: white;
}

.icon-overlay-image {
  background-color: var(--color-label-secondary);
}

#feedback-button {
  position: absolute;
  right: 15px;
  width: 30px;
  bottom: 90px;
  height: 80px;
  background-color: #b41010;
  color: white;
  writing-mode: tb;
  font-size: 14px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: var(--color-input-bg);
  color: var(--color-input);
  border-width: 1px;
  border-color: var(--color-border);
  display: none;
}