.ngtextarea {
  --color: var(--color-input) d;
  --bg-color: var(--color-input-bg);
  --border-color: var(--color-input-border);

  width: 100%;
  font-size: 1rem;
  line-height: var(--line-height-medium);
  padding: 8px 10px;
  color: var(--color);
  background-color: var(--bg-color);

  border-radius: var(--border-radius);
  border-width: 1px;
  border-color: var(--border-color);
  overflow-y: hidden;
  resize: none;
  display: block;

  &.disabled {
    --bg-color: var(--color-gray-100);
    --color: var(--color-gray-500);
    cursor: not-allowed;

    &:hover,
    &:active {
      --bg-color: var(--color-gray-100);
      --color: var(--color-gray-500);
    }
  }
}

.ngtextarea.small {
  padding: 5px 10px;
  font-size: 0.875rem; /* 14px */
  line-height: var(--line-height-small);
}

.ngtextarea.invalid {
  --border-color: var(--color-danger);
}
.ngtextarea.warning:not(.invalid) {
  --border-color: var(--color-warning);
}

.ngtextarea::placeholder {
  color: var(--color-placeholder);
}

.dark .ngtextarea {
  &.disabled {
    --bg-color: var(--color-gray-1400);
    --color: var(--color-gray-900);
    cursor: not-allowed;

    &:hover,
    &:active {
      --bg-color: var(--color-gray-1400);
    }
  }
}
