.tile {
  width: 100px;
  height: 100px;

  --color: white;
  --activeColor: white;

  &Light {
    --color: rgb(202, 202, 202);
    --activeColor: #508aff;
    color: var(--color);
    border-color: var(--color);
  }

  &Dark {
    --color: #8a8a8a;
    --activeColor: var(--link-500);
    color: var(--color);
    border-color: var(--color);
  }

  &Active {
    color: var(--activeColor);
    border-color: var(--activeColor);
  }
}
