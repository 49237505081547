/* Header */
.ngheader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  &-logo {
    display: block;
    margin-left: 2.25rem;
    margin-right: 1rem;
    // width: 6rem; /* 96px */
  }

  &-spacer {
    flex-grow: 1;
  }

  &-right-controls {
    display: flex;
    align-items: center;
    gap: 1.25rem; /* 20px */
  }
}

.ngheader .ngheader-left-controls {
  display: flex;
  align-items: center;
  // gap: 2.25rem; /* 36px */
}

/* User Menu */
.user-menu {
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* 4px */
  padding: 6px 12px !important;

  svg {
    color: var(--color-label);
  }

  span {
    color: var(--color-label);
  }
}

.user-menu-profile {
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
}

.user-menu-profile-details {
  display: flex;
  flex-direction: column;
}

.user-menu-create-account {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-menu-create-account svg {
  transition: var(--transition);
  color: var(--color-action-button);
}
.user-menu-create-account:hover svg {
  color: var(--color-action-button-hover);
}

.user-menu-date-format {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.user-menu-log-out {
  display: flex;
  align-items: center;
  gap: 0.75rem; /* 12px */
}

.nguser-avatar-container {
  position: relative;
}

.nguser-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.nguser-avatar-image {
  vertical-align: middle;
  border-radius: 50%;
}

.nguser-avatar-initials {
  color: var(--color-white) !important;
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
}

.nguser-avatar-initials-large {
  font-size: 0.5rem;
}

.nguser-avatar-lock {
  position: absolute;
  color: var(--color-white);
  stroke-width: 2;
  stroke: currentColor;
}
