#affix-root-disallowed-email .ant-alert-message {
  font-size: 44px;
}

.ant-select-time .ant-select-selector {
  padding-left: 0 !important;
}

.ant-select-selection-placeholder {
  color: rgb(74, 85, 104) !important;
  opacity: 0.7;
}

.ant-pagination-prev a span,
.ant-pagination-next a span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ant-notification {
  z-index: 9999;
}

.ant-picker-range-separator {
  margin-top: 7px !important;
  margin-bottom: 10px !important;
}

.ant-modal-content {
  width: inherit;
}

.ant-select-multiple .ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}

.ant-modal,
.ant-modal-body {
  font-size: 1rem;
}

.ant-modal-close {
  color: var(--color-label) !important;
}

.ant-dropdown-menu {
  width: max-content;
  max-width: 500px;
}

.dark .ant-dropdown-menu-item-danger {
  background-color: var(--color-danger) !important;
  color: white;

  &:not(.ant-dropdown-menu-item-disabled):hover {
    background-color: var(--color-red-500) !important;
  }
}

.dark .ant-modal-content,
.dark .ant-modal-header,
.dark .ant-notification-notice,
.dark .ant-dropdown-menu,
.dark .ant-dropdown-menu-submenu,
.dark .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title,
.dark .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled) {
  background-color: var(--color-drop);
  border-color: var(--color-border) !important;
  color: var(--color-default) !important;
}

.dark .ant-dropdown-menu-submenu-arrow-icon {
  color: var(--color-default) !important;
}

.dark .ant-dropdown-menu-item-disabled,
.dark .ant-dropdown-menu-item-disabled:hover {
  background-color: var(--color-drop) !important;
  color: #888 !important;
}

.dark .ant-dropdown .ant-progress-text {
  color: inherit;
}

.dark .ant-tabs,
.dark .ant-tabs-tab-btn {
  color: var(--color-default);
}

.dark .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: var(--color-border);
}

.ant-picker-range-separator {
  margin-top: 7px !important;
  margin-bottom: 10px !important;
}

.ant-modal-content {
  width: inherit;
}

.ant-select-multiple .ant-select-selection-item-remove {
  display: flex !important;
  align-items: center !important;
}

.dark .ant-select-multiple .ant-select-selection-item {
  color: var(--color-label);
  background-color: var(--color-drop);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector span {
  color: var(--color-label-secondary) !important;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
}

.ant-select-selection-item-remove {
  transform: translateY(1px);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  background-color: var(--color-white);
  border-color: var(--color-input-border);
}

.dark .ant-select .ant-select-arrow {
  color: var(--color-label);
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--color-gray-1300);
}

.dark .ant-switch {
  background-color: #555;
}

.dark .ant-switch-checked {
  background-color: #1890ff;
}

.dark .ant-modal-footer {
  border-color: var(--color-border);
}

.dark .ant-picker-range:hover .ant-picker-suffix {
  visibility: hidden;
}

.dark .ant-picker-range:hover .ant-picker-clear {
  background: transparent;
}

.dark .ant-dropdown-menu-item-divider {
  background-color: var(--color-border);
}

.dark .ant-radio-disabled .ant-radio-inner {
  border-color: #6d6d6d !important;
  background-color: #8d8d8d;
}

.dark .ant-alert-error {
  background-color: #ffccc7;
  border: 1px solid #e8b0ab;
}

.dark .ant-alert-warning {
  background-color: #ffd48f;
  border: 1px solid #e7bc77;
}

.ant-modal-confirm-body {
  margin-bottom: 0.75rem; /* 12px */
}
.dark .ant-modal-confirm-title {
  color: var(--color-label);
}
.dark .ant-modal-confirm-content {
  color: var(--color-label-secondary);
}

.ant-modal .ant-modal-title {
  color: var(--color-label);
}

.cplnapplymodal .ant-modal-content,
.mk8sscriptmodal .ant-modal-content,
.codeEditorModal .ant-modal-content {
  width: 100% !important;
}

.codeEditorModal .ant-modal-content {
  min-width: 650px !important;
}

.codeEditorModal .ant-modal-body {
  padding: 0;
}

.dryrun.mk8s .ant-alert {
  min-height: 48px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
  background: var(--color-gray-50);
}

.dark {
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover:not(.ant-dropdown-menu-item-disabled),
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
    background: var(--color-gray-1300);
  }
}

.ant-select-time .ant-select-selector {
  padding-left: 0 !important;
}

.ant-select-selection-placeholder {
  color: rgb(74, 85, 104) !important;
  opacity: 0.7;
}

.ant-pagination-prev a span,
.ant-pagination-next a span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ant-checkbox-wrapper {
  color: var(--color-default);
}

.ant-modal,
.ant-modal-body {
  font-size: 1rem;
}

.ant-modal-close {
  color: var(--color-label) !important;
}

.dark .ant-modal-content,
.dark .ant-modal-header,
.dark .ant-notification-notice {
  background-color: var(--color-drop);
  border-color: var(--color-border) !important;
  color: var(--color-default) !important;

  &
    span:not(.cm-line > span):not(.ngbutton > span):not(.ngalert span):not(.formmark):not(.ant-alert-content
      span):not(.ant-notification-notice-with-icon span):not(span.nglabel) {
    color: var(--color-default) !important;
  }
}

.dark .ant-dropdown .ant-progress-text {
  color: inherit;
}

.dark .ant-select-multiple .ant-select-selection-item {
  color: var(--color-label);
  background-color: var(--color-drop);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector span {
  color: var(--color-label-secondary) !important;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
}

.ant-select-selection-item-remove {
  transform: translateY(1px);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  background-color: var(--color-white);
  border-color: var(--color-input-border);
}

.dark .ant-select .ant-select-arrow {
  color: var(--color-label);
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--color-gray-1300);
}

.dark .ant-switch {
  background-color: #555;
}

.dark .ant-switch-checked {
  background-color: #1890ff;
}

.dark .ant-modal-footer {
  border-color: var(--color-border);
}

.dark .ant-picker-range:hover .ant-picker-suffix {
  visibility: hidden;
}

.dark .ant-picker-range:hover .ant-picker-clear {
  background: transparent;
}

.dark .ant-dropdown-menu-item-divider {
  background-color: var(--color-border);
}

.dark .ant-radio-disabled .ant-radio-inner {
  border-color: #6d6d6d !important;
  background-color: #8d8d8d;
}

.dark .ant-alert-error {
  background-color: #ffccc7;
  border: 1px solid #e8b0ab;
}

.dark .ant-alert-warning {
  background-color: #ffd48f;
  border: 1px solid #e7bc77;
}

.cplnapplymodal .ant-modal-content,
.mk8sscriptmodal .ant-modal-content,
.codeEditorModal .ant-modal-content {
  width: 100% !important;
}

.codeEditorModal .ant-modal-content {
  min-width: 650px !important;
}

.codeEditorModal .ant-modal-body {
  padding: 0;
}

.dryrun.mk8s .ant-alert {
  min-height: 48px;
}

#affix-root-disallowed-email .ant-alert-message {
  font-size: 44px;
}

.ant-notification {
  z-index: 9999;
}

/* Notification */
.ant-notification .ant-notification-notice-wrapper {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 20px;
  background-color: var(--color-drop);
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-with-icon .ant-notification-notice-message {
  color: var(--color-label);
  font-weight: var(--semibold);
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  font-size: 16px;
  color: var(--color-label-secondary);
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  color: var(--color-label);

  &:hover {
    color: var(--color-label-secondary);
  }
}

/* Date Picker */
.ant-picker {
  background-color: var(--color-input-bg);
  border-color: var(--color-input-border);

  &:hover > {
    .ant-picker .ant-picker-suffix {
      color: var(--color-input);
    }
    color: var(--color-input);
  }
}

.ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-separator,
.ant-picker .ant-picker-suffix {
  color: var(--color-input);
  visibility: visible !important;
}

.ant-picker .ant-picker-input > input::placeholder {
  color: var(--color-placeholder);
}

.ant-picker-outlined:hover,
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
  border-color: var(--color-border);
  background-color: var(--color-input-bg);
}

.ant-radio-wrapper span:nth-of-type(2) {
  color: var(--color-default);
}

.ant-alert-message.ant-alert-message.ant-alert-message,
.ant-alert-message.ant-alert-message.ant-alert-message * {
  color: var(--color-gray-800) !important;
}

// after select with tags mode for retry policy
.dark .ant-select-dropdown {
  background-color: var(--color-gray-1400);
}
.dark .ant-select-dropdown .ant-select-item {
  background-color: transparent;
  color: var(--color-default);
}
.dark .ant-select-dropdown .ant-select-item-option-selected {
  background-color: var(--color-gray-1600);
  font-weight: 400;
}

.ant-select-selector {
  transition: none !important;
}