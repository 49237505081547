.ngswitch {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--color-label);

  rect,
  circle {
    transition: var(--transition);
  }

  &.notAllowed {
    cursor: not-allowed;
  }

  .visual {
    .ngswitch-toggle-rect {
      fill: var(--color-gray-300);
      &.selected {
        fill: var(--color-blue-300);
      }
    }

    &:hover {
      .ngswitch-toggle-rect {
        fill: var(--color-gray-500);
        &.selected {
          fill: var(--color-blue-400);
        }
      }
    }
  }

  &.disabled .visual {
    opacity: 0.5;

    &:hover {
      .ngswitch-toggle-rect {
        fill: var(--color-gray-300);
        &.selected {
          fill: var(--color-blue-300);
        }
      }
    }
  }
}

.dark .ngswitch {
  .visual {
    .ngswitch-toggle-rect {
      fill: var(--color-gray-1000);
      &.selected {
        fill: var(--color-blue-400);
      }
    }

    &:hover {
      .ngswitch-toggle-rect {
        fill: var(--color-gray-900);
        &.selected {
          fill: var(--color-blue-300);
        }
      }
    }
  }

  &.disabled .visual {
    &:hover {
      .ngswitch-toggle-rect {
        fill: var(--color-gray-1000);
        &.selected {
          fill: var(--color-blue-400);
        }
      }
    }
  }
}
