.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: max(1200px, 100vw);
  min-height: max(800px, 100vh);

  & > * {
    font-weight: 500;
  }
}

.header {
  width: 100%;
  height: 70px;
  padding: 13px 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 100%;
  width: auto;
  & > img {
    object-fit: contain;
    height: 100%;
    width: auto;
  }
}

.already {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
    font-size: 14px;
    line-height: 14px;
    color: #1c2350;
  }
  & a:nth-child(2) {
    font-size: 14px;
    line-height: 14px;
    color: #1564ff;
    font-weight: 400;
  }
  & a:hover {
    text-decoration: underline;
    color: #286aed;
  }
}

.divider {
  margin-inline: 16px;
  height: 27px;
  width: 1px;
  background-color: #c1c1c1;
}

.support {
  font-size: 14px;
  line-height: 14px;
  color: #76798d;
}

.subContainer {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
}

.contentRight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 100px;
  flex-grow: 1;
}

.header_one {
  font-size: 30px;
  line-height: 30px;
  color: #1c2350;
  margin-bottom: 20px;
}
.header_two {
  font-size: 16px;
  line-height: 16px;
  color: #1c2350;
  margin-bottom: 20px;
}

.formParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tou {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #76798d;
  margin-bottom: 30px;
  & > a {
    color: #1564ff;
  }
}
.infoText {
  width: 100%;
  text-align: center;
  color: #76798d;
  font-size: 16px;
  margin-bottom: 30px;
  &::after,
  &::before {
    content: "";
    display: inline-block;
    width: 70px;
    height: 1px;
    background-color: #76798d;
    opacity: 0.2;
  }
  &::after {
    margin: 5px 0 5px 10px;
  }
  &::before {
    margin: 5px 10px 5px 0;
  }
}

.form {
  width: 386px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  & > * {
    width: 100%;
  }
}

.buttonAndPasswordRules {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.providers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  & > .provider:nth-child(3) {
    & > div:nth-child(2) {
      transform: translateX(-2px);
    }
  }
}

.provider {
  cursor: pointer;
  &:hover > div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  & > div:nth-child(1) {
    border: 2px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 16px;
    & > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
  }
  & > div:nth-child(2) {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
    color: #76798d;
  }
}

.allDone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div:nth-child(1) {
    font-size: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span:nth-child(1) {
      text-transform: uppercase;
      color: #1564ff;
      font-weight: 900;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 2px;
    }
    & > span:nth-child(2) {
      color: #1c2350;
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
    }
  }

  & > div:nth-child(2) {
    font-size: 20px;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      margin-left: 5px;
      font-weight: bold;
    }
  }

  & > div:nth-child(3) {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 67px;
    & > * {
      text-align: center;
    }
  }

  & > div:nth-child(4) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    & > button {
      color: rgb(46, 80, 204);
    }
  }
}

.workEmailError {
  background-color: #f650501a;
  color: #f65050;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.invalidEmail {
  margin: 0px;
  color: #f65050;
  font-size: 0px;
  line-height: 0px;
  transform: scaleY(0);
  transition: all 0.1s ease-in-out;
}

.invalidEmailActive {
  transform: scaleY(1);
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 14px;
}

.names {
  display: flex;
  gap: 10px;
}

.resetPassword {
  text-align: right;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 14px;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    color: #286aed;
    text-decoration: underline;
    cursor: pointer;
  }
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
  width: 386px;
}
