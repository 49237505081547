.template-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1rem;
}

.template-card-name {
  transition: color 0.15s;
}
.template-card:hover .template-card-name {
  color: var(--color-primary);
}
