.nglabel {
  display: block;
  color: var(--color-label);
  font-weight: 500;
  font-size: 1rem;
  width: fit-content;
}

.nglabel-size-1 {
  font-size: 1.35rem;
  font-weight: 400;
}
.nglabel-size-2 {
  font-size: 1.125rem;
}
.nglabel-size-3 {
  font-size: 1rem;
}
.nglabel-size-4 {
  font-size: 0.75rem;
}

.nglabel.cursorPointer {
  cursor: pointer;
}
