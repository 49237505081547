.signin-page .controlplane-logo {
  width: 220px;
}

.signin-page .text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.signin-page .text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.signin-page .text-footer {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

@media (max-width: 1024px) {
  .signin-page .text-2xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

@media (max-width: 768px) {
  .signin-page .text-2xl {
    padding-inline: 60px;
    text-align: center;
  }
}

@media (max-width: 640px) {
  .signin-page .controlplane-logo {
    width: 160px;
  }

  .signin-page .text-4xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }
  .signin-page .text-2xl {
    padding-inline: 0;
    text-align: left;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
  .signin-page .text-footer {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}
