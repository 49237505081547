div[data-reach-dialog-overlay] {
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}
div[data-reach-dialog-content] {
  padding: 0;
  aspect-ratio: 16/9;
  border-radius: 4px;
}
@media screen and (orientation: portrait) {
  div[data-reach-dialog-content] {
    min-width: 80vw;
  }
}
@media screen and (orientation: landscape) {
  div[data-reach-dialog-content] {
    min-height: 80vh;
  }
}
div[data-reach-dialog-content] iframe {
  background-color: black;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
