/* Select Window */
.select-window {
  display: flex;
  height: 400px;
  width: 270px;
  padding: 16px 5px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  min-width: min-content;

  border: 1px solid;
  border-color: var(--color-gray-400);

  border-radius: 6px;
  background: var(--color-white);
}

.select-window-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px;
}

.select-window-head-titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;

  /* Text Styles */
  word-break: break-all;
  line-height: 1.1;
  letter-spacing: var(--letter-spacing);
}
.select-window-head-titles span {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-label);
}
.select-window-head-titles label {
  font-size: 16px;
  color: var(--color-label-secondary);
}

.select-window-head-bookmark {
  display: none;
  cursor: pointer;
  transition: all 400ms;
}
.select-window-head-bookmark:hover path {
  transition: all 400ms;
  fill: var(--color-blue-50);
}
.select-window-head-bookmark.visible {
  display: block;
  cursor: pointer;
  transition: all 400ms;
}

.no-items-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 26px 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--color-gray-50);
  border-radius: var(--border-radius);

  /* Text Styles */
  text-align: center;
  color: var(--color-label);
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
}

.no-items-found.space {
  margin: 0 15px;
}

.dark .no-items-found {
  background: var(--color-gray-1300);
}

.select-window-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 0 15px;
}

.select-window-list {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* 4px */
  height: 100%;
}

.dark .select-window {
  border-color: var(--color-gray-1200);
  background: var(--color-gray-1600);
}
