.ngbreadcrumb {
  display: flex;

  /* Text styles */
  color: var(--color-label);
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: var(--letter-spacing);
}

@media (max-width: 768px) {
  .ngbreadcrumb.billing {
    display: none;
  }
}

.ngbreadcrumb-link {
  transition: var(--transition);
  position: relative;
  line-height: 1.5;
}
.ngbreadcrumb-link::after {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  background-color: var(--color-label);
}

.ngbreadcrumb-link:hover {
  color: var(--color-link);
  border-color: var(--color-link);
}
.ngbreadcrumb-link:hover::after {
  background-color: var(--color-link);
}

.ngbreadcrumb-divider {
  margin: 0 7px;
}
