.svgIconDefault {
  width: 30px;
  height: 30px;
  fill: var(--color-default);
  stroke: var(--color-default);
  & * {
    fill: var(--color-default);
    stroke: var(--color-default);
  }
}
