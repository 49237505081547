.export-with-items-modal {
  &-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &-column {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    width: 32%;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    height: 300px;
    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.375rem /* 6px */ 0.75rem /* 12px */;
      border-bottom: 1px solid var(--color-border);
      text-transform: capitalize;
    }
    &-no-items-found {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-list {
      height: 100%;
      display: flex;
      flex-direction: column;
      &.centerItems {
        align-items: center;
        justify-content: center;
      }
    }
  }
}
