.ngselect {
  --color: var(--color-input);
  --bg-color: var(--color-input-bg);
  --border-color: var(--color-input-border);

  --icon-width: 1.25em;
  position: relative;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  width: 100%;
}

.dark .ngselect {
  --bg-color: var(--color-gray-1300);
  --border-color: var(--color-gray-1300);
}

.ngselect > div {
  height: 100%;
  width: 100%;
}
.ngselect > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  padding-inline: 10px;
}
.ngselect > div > div > input {
  width: 100%;

  position: relative;
  border-radius: var(--border-radius);

  color: var(--color);
  background-color: transparent;

  font-size: 1rem;
}

.ngselect.large > div > div > input {
  padding-block: 8.5px;
}
.ngselect.large > div > div > button {
  margin-block: 8.5px;
}

.ngselect.normal > div > div > input {
  padding-block: 4.5px;
}
.ngselect.normal > div > div > button {
  margin-block: 4.5px;
}

.ngselect.small > div > div > input {
  padding-block: 1.5px;
}
.ngselect.small > div > div > button {
  margin-block: 1.5px;
}

.ngselect > div > div > input::placeholder {
  color: var(--color-placeholder);
}

.ngselect > div > ul {
  position: absolute;
  display: none;
  left: -2px;
  right: -2px;
  top: calc(100% + 5px);
  background-color: var(--bg-color);
  border: 1px solid;
  border-radius: var(--border-radius);
  border-color: var(--border-color);
  z-index: 3;
  box-shadow: 0px 1.6px 1.9px rgba(0, 0, 0, 0.017), 0px 3.6px 4.2px rgba(0, 0, 0, 0.024),
    0px 6.3px 7.3px rgba(0, 0, 0, 0.03), 0px 10px 11.6px rgba(0, 0, 0, 0.035), 0px 15.5px 18px rgba(0, 0, 0, 0.04),
    0px 24.1px 28px rgba(0, 0, 0, 0.046), 0px 40px 46.5px rgba(0, 0, 0, 0.053), 0px 80px 93px rgba(0, 0, 0, 0.07);
}

.ngselect > div > ul.open {
  display: block;
}

.ngselect.invalid {
  --border-color: var(--color-danger);
}
.ngselect.warning:not(.invalid) {
  --border-color: var(--color-warning);
}

.ngselect > div > ul li {
  padding: 0.25rem 0.5rem;
  cursor: default;
}

.ngselect.small > div > ul li,
.ngselect.normal > div > ul li {
  padding-block: 0.25rem;
}

.ngselect.error {
  --color: var(--color-error);
  --border-color: var(--color-error);
}

/* Showing empty characters */
.ngselect .clone {
  pointer-events: none;
  position: absolute;
  inset: 0;
  padding-block: calc(0.6rem - 1px);
  padding-left: 1.2rem;
  font-size: 1rem;
  font-family: "proxima-nova", sans-serif;
  display: flex;
}

.ngselect .clone > .startsWith,
.ngselect .clone > .endsWith {
  display: flex;
  height: 100%;
}

/* TODO get width of each character and render them transparent? */

.ngselect .clone .emphasizeIndicator {
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
}

.ngselect .width-calculator {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.option-hover {
  background-color: var(--color-gray-50);
}
.dark .option-hover {
  background-color: var(--color-gray-1100);
}
.option-selected.option-selected {
  background-color: var(--color-gray-200);
}
.dark .option-selected.option-selected {
  background-color: var(--color-gray-900);
}
