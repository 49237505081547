.jobExecution {
  .statusActive {
    color: var(--color-primary);
  }
  .statusSuccessful {
    color: var(--color-action);
  }
  .statusFailed,
  .statusInvalid,
  .statusRemoved {
    color: var(--color-danger);
  }
}
