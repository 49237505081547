select.custom-tab {
  background-color: var(--color-drop-alternative);
}

.custom-tab.active {
  border-bottom-width: 2px;
  color: var(--color-link);
  border-color: var(--color-link);
}

.custom-tab.enabled:hover {
  color: var(--color-link);
}

.custom-tab.disabled {
  color: var(--color-gray-200);
}
.dark .custom-tab.disabled {
  color: var(--color-gray-700);
}
