/* Tabs */
.ngtabs {
  display: flex;
  gap: 0.625rem; /* 10px */
  border-bottom: 1px solid var(--color-border);

  &-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 20px */
  }

  &-tab {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;

    outline: none;
    cursor: pointer;
    line-height: normal;
    transition: var(--transition);
    color: var(--color-label);

    &:hover {
      color: var(--color-label-secondary);
      border-bottom: 1px solid var(--color-border);
    }

    &.selected {
      color: var(--color-blue-300);
      border-bottom: 1px solid var(--color-blue-300);

      &:hover {
        background-color: transparent;
      }
    }
    &.disabled {
      color: var(--color-disabled);
      cursor: not-allowed;
    }
  }
}
