.testimonial {
  background-color: #f8fbff;
  border-radius: 10px;
  margin: 24px auto;
  transform: skew(-10deg);
  padding: 40px;
  max-width: 800px;

  > * {
    transform: skew(10deg);
  }

  .quote {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.3;

    @media (max-width: 768px) {
      & {
        font-size: 16px;
      }
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 48px;

    @media (max-width: 768px) {
      & {
        margin-top: 24px;
      }
    }

    span,
    cite {
      display: block;
    }

    span {
      font-size: 24px;
      @media (max-width: 768px) {
        & {
          font-size: 16px;
          line-height: 1;
        }
      }
    }

    cite {
      margin-top: 4px;
      font-size: 18px;
      @media (max-width: 768px) {
        & {
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
  }

  .photo {
    display: inline-block;
    object-fit: cover;
    height: 70px;
    width: 70px;
    border-radius: 9999px;
    margin-right: 16px;

    @media (max-width: 768px) {
      & {
        height: 34px;
        width: 34px;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='229' height='176' viewBox='0 0 229 176' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.15' d='M109.14 2V1H108.14H32.8721H31.8721V2V53.2765C31.8721 73.0904 28.8297 90.4422 22.7723 105.353L22.769 105.361C16.8309 120.362 9.95878 134.027 2.15502 146.357L1.60343 147.228L2.49158 147.752L46.7117 173.861L47.3223 174.222L47.8646 173.765C58.9145 164.451 69.0856 153.173 78.3809 139.939L78.3841 139.935C87.6823 126.539 95.0915 112.352 100.61 97.3756C106.292 82.2231 109.14 67.3648 109.14 52.8061V2ZM227.453 2V1H226.453H151.184H150.184V2V53.2765C150.184 73.0904 147.142 90.4422 141.085 105.353L141.081 105.361C135.144 120.36 128.195 134.021 120.237 146.349L119.672 147.223L120.569 147.752L164.789 173.861L165.395 174.219L165.936 173.769C177.145 164.455 187.396 153.176 196.693 139.939L196.696 139.935C205.995 126.539 213.404 112.352 218.922 97.3745C224.604 82.2223 227.453 67.3644 227.453 52.8061V2Z' stroke='%231563FF' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    right: 100px;
    bottom: 20px;

    @media (max-width: 768px) {
      & {
        height: 60px;
        width: 60px;
        right: 20px;
        bottom: 10px;
      }
    }
  }
}
