.page {
  width: 100%;
}

.container {
  display: flex;
  gap: 40px;
  position: relative;
}

.content {
  width: min(100%);
}

.help {
  width: 260px;
  align-self: flex-start;
  position: absolute;
  max-height: min(100vh - 190px);
  right: 0;
  overflow-y: scroll;

  // disabled help
  display: none;
}
