/* Radio */
.ngradio {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem; /* 4px */
  color: var(--color-default);

  &:hover {
    .ngradio-circle {
      stroke: var(--color-blue-200);
    }
  }

  .ngradio-circle {
    transition: all 400ms;
    stroke: var(--color-gray-200);

    &.selected {
      stroke: var(--color-blue-200);
    }
  }

  /* Radio / States */
  &.notAllowed {
    cursor: not-allowed;
  }

  &.disabled {
    opacity: 0.5;
    &:hover {
      .ngradio-circle {
        stroke: var(--color-gray-200);
      }
    }
  }
}

/* Radio group / States */
.ngradio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &.vertical {
    flex-direction: column;
    gap: 0px;
  }

  &.withLabel {
    margin-top: 10px;
  }
}
