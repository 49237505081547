.detailed-radio-button {
  --active-color: #508aff;
  --disabled-border-color: #f2f2f2;
  --disabled-text-color: #d2d2d2;
  --transition: 80ms linear;

  .cardContent {
    transition: var(--transition);
  }

  .cardContentActive {
    border-color: var(--active-color);
  }

  .cardContentActiveLight {
    background-color: #e6f2ff;
  }

  .checkIcon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    border: 2px solid #e3e3e3;
    border-radius: 50%;
    transition: var(--transition);
  }

  .checkIconActive {
    background-color: var(--active-color);
    border-color: var(--active-color);
  }

  .innerCircle {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
  }

  &.disabled {
    color: var(--disabled-text-color);
    border-color: var(--disabled-border-color);
    cursor: not-allowed;
  }
  &.disabled .checkIcon {
    border-color: var(--disabled-text-color);
  }
}

.dark .detailed-radio-button {
  --disabled-border-color: #282828;
  --disabled-text-color: #4f4f4f;
}
