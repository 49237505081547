:root {
  /* Variables */
  --root-font-size: 16px;
  --semibold: 600;
  --letter-spacing: 0.32px;
  --line-height: normal;
  --line-height-medium: 1.1875;
  --line-height-small: 1.0625;
  --border-radius: 6px;
  --transition: 200ms;
  --box-shadow: 0px 0px 6px 0px rgba(210, 210, 210, 0.9);

  /* Connect nav and header size, icon sizes to rem */
  --nav-collapsed-width: 80px;
  --nav-expanded-width: 260px;
  --nav-width: var(--nav-expanded-width);

  /* Style variables */
  --color-default: var(--color-gray-1400);
  --color-complementary: var(--color-white);
  /* --color-label: var(--color-gray-700); */
  --color-label: var(--color-gray-600);
  /* TODO looks like we don't need label secondary */
  --color-label-secondary: var(--color-gray-1000);
  /* --color-input: var(--color-gray-900); */
  --color-input: var(--color-default);
  --color-input-bg: var(--color-drop);
  --color-input-border: var(--color-gray-300);
  --color-disabled: var(--color-gray-400);
  --color-placeholder: var(--color-gray-400);
  --color-border: rgba(210, 210, 210, 0.2);
  --color-danger: var(--color-red-400);
  --color-warning: rgb(255, 140, 0);
  --color-link: var(--color-blue-300);
  --color-link-hover: var(--color-blue-400);
  --color-primary: var(--color-blue-300);
  --color-action: var(--color-green-400);
  /* TODO we should not need below two */
  --color-action-button: var(--color-green-300);
  --color-action-button-hover: var(--color-green-200);

  /* For tables */
  /* These should be under table component css */
  --color-table-label: var(--color-gray-800);
  --color-table-label-drop: var(--color-gray-50);
  --color-table-border: var(--color-gray-400);
  --color-table: var(--color-gray-600);
  --color-table-action: var(--color-green-400);
  --color-table-danger: var(--color-red-400);
  --color-table-primary: var(--color-blue-300);
  /* For tables end */

  /* For alerts */
  --color-alert-info-drop: var(--color-blue-50);
  --color-alert-info-label: var(--color-blue-300);

  --color-alert-warning-drop: var(--color-yellow-50);
  --color-alert-warning-label: var(--color-yellow-300);

  --color-alert-error-drop: var(--color-red-50);
  --color-alert-error-label: var(--color-red-500);

  --color-alert-success-drop: var(--color-green-50);
  --color-alert-success-label: var(--color-green-500);
  /* For alerts end */

  /* TODO we have a third color on layout, should be here */
  --color-drop: var(--color-white);
  --color-drop-secondary: var(--color-gray-50);

  /* Backward compatibility */
  /* TODO should remove and move to identity related css */
  --identity-aws-logo-drop: white;
  --identity-aws-logo-accent: #ff9900;
  --identity-azure-logo-drop: #090e17;
  --identity-azure-logo-accent: #090e17;
  --identity-gcp-logo-drop: white;
  --identity-gcp-logo-accent: rgb(66, 133, 244);
}

.dark {
  /* --color-default: var(--color-gray-200); */
  --color-default: var(--color-gray-100);
  --color-complementary: var(--color-1500);
  /* --color-label: var(--color-gray-400); */
  --color-label: var(--color-gray-500);
  --color-label-secondary: var(--color-gray-300);
  /* --color-input: var(--color-gray-300); */
  --color-input: var(--color-default);
  --color-input-bg: var(--color-gray-1300);
  --color-input-border: var(--color-gray-1300);
  --color-disabled: var(--color-gray-1400);
  --color-placeholder: var(--color-gray-600);
  --color-border: rgba(50, 53, 57, 0.5);
  --color-link: var(--color-blue-200);
  --color-link-hover: var(--color-blue-300);
  --color-warning: rgb(255, 140, 0);
  --color-action: var(--color-green-300);
  --color-action-button: var(--color-green-600);
  --color-action-button-hover: var(--color-green-500);
  --box-shadow: 0px 0px 0px 0px rgba(92, 95, 99, 0.9);

  --color-drop: var(--color-gray-1600);
  --color-drop-secondary: var(--color-gray-1400);

  /* For tables */
  --color-table-label: var(--color-gray-200);
  --color-table-label-drop: var(--color-gray-1400);
  --color-table-border: var(--color-border);
  --color-table: var(--color-gray-300);
  --color-table-action: var(--color-green-300);
  --color-table-danger: var(--color-red-300);
  --color-table-primary: var(--color-blue-200);

  /* For alerts */
  --color-alert-info-drop: var(--color-blue-700);
  --color-alert-info-label: var(--color-blue-200);

  --color-alert-warning-drop: var(--color-yellow-400);
  --color-alert-warning-label: var(--color-yellow-200);

  --color-alert-error-drop: var(--color-red-600);
  --color-alert-error-label: var(--color-red-300);

  --color-alert-success-drop: var(--color-green-800);
  --color-alert-success-label: var(--color-green-200);
  /* For alerts end */
}

.dark:has(.keep-light) {
  /* For alerts */
  --color-alert-info-drop: var(--color-blue-50);
  --color-alert-info-label: var(--color-blue-300);

  --color-alert-warning-drop: var(--color-yellow-50);
  --color-alert-warning-label: var(--color-yellow-300);

  --color-alert-error-drop: var(--color-red-50);
  --color-alert-error-label: var(--color-red-500);

  --color-alert-success-drop: var(--color-green-50);
  --color-alert-success-label: var(--color-green-500);
  /* For alerts end */
}

html body {
  /* Global values */
  font-size: var(--root-font-size);
  color: var(--color-default);
  overflow: hidden;
}

html:has(.signin-page) body:has(.signin-page) {
  overflow-y: auto;
}

html,
body {
  /* Text styles */
  font-weight: 400;
  line-height: var(--line-height);
  overflow: hidden;
}

html:has(.signin-page),
body:has(.signin-page) {
  overflow-y: auto;
}

#page {
  display: grid;
  grid-template-areas:
    "header header"
    "nav  main";
  --header-height: 63px;
  letter-spacing: var(--letter-spacing);
  grid-template-rows: var(--header-height) 1fr;
  grid-template-columns: var(--nav-width) calc(100vw - var(--nav-width));
  width: 100vw;
  height: 100svh;
}
@media (max-width: 1280px) {
  #page:has(.signin-page) {
    overflow-y: auto;
  }
}

#page.pinned {
  grid-template-areas:
    "header header header"
    "nav pinned main";
  grid-template-columns: var(--nav-width) 271px 1fr;
}

#page.noSidebar {
  grid-template-areas:
    "header header header"
    "main main main";
  grid-template-columns: 1fr;
}

#page header {
  --bg-color: var(--color-drop);
  --border-color: var(--color-border);

  grid-area: header;
  border-bottom: 1px solid;

  /* padding: 0.625rem 1.75rem; */
  padding: 0 1.75rem;

  background-color: var(--bg-color);
  border-color: var(--border-color);
}

#page nav.main {
  --bg-color: var(--color-drop);
  --border-color: var(--color-border);

  grid-area: nav;
  display: flex;
  flex-direction: column;

  border-right: 1px solid;

  background-color: var(--bg-color);
  border-color: var(--border-color);
}

#page nav.pinned {
  --bg-color: var(--color-drop);
  --border-color: var(--color-border);

  grid-area: pinned;
  display: flex;
  flex-direction: column;

  border-right: 1px solid;

  background-color: var(--bg-color);
  border-color: var(--border-color);
}

#page nav.pinned.hidden {
  display: none;
}

#page main {
  --bg-color: var(--color-white);

  grid-area: main;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  background-color: var(--bg-color);
}

#page.dark main {
  --bg-color: var(--color-gray-1500);
}

#page main section {
  padding: 30px;
  flex-grow: 1;
  min-width: 1125px;
}
#page main section:has(.onboard-landing-page),
#page main section:has(.account-create) {
  min-width: auto;
}
#page main section:has(.marketplace):has(.template-list),
#page main section:has(.marketplace):has(.template-detail),
#page main section:has(.marketplace):has(.release-list),
#page main section:has(.marketplace):has(.release-detailcancelled) {
  /* background-color: rgb(245, 245, 245); */
}
.dark #page main section:has(.marketplace):has(.template-list),
.dark #page main section:has(.marketplace):has(.template-detail),
.dark #page main section:has(.marketplace):has(.release-list),
.dark #page main section:has(.marketplace):has(.release-detailcancelled) {
  /* background-color: var(--gray-1500); */
}

#page main section .marketplace .template-detail {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 260px;
  gap: 1rem;
}

#page main footer {
  --bg-color: var(--color-drop);
  --border-color: var(--color-border);
  background-color: var(--bg-color);
  border-color: var(--border-color);
}

.color-default {
  color: var(--color-default);
}
.color-primary {
  color: var(--color-primary);
}
.color-action {
  color: var(--color-action);
}
.color-danger {
  color: var(--color-danger);
}
.color-warning {
  color: var(--color-warning);
}
.color-link,
.link {
  color: var(--color-link);
}
.color-link:hover,
.color-link-hover:hover,
.link:hover {
  color: var(--color-link-hover);
}
.view-modal-button:hover {
  color: var(--color-link-hover);
  border-color: var(--color-link-hover);
}
.view-modal-button:disabled {
  color: var(--color-disabled);
  border-color: var(--color-disabled);
  cursor: not-allowed;
}
.color-input {
  color: var(--color-input);
}

.color-card-drop {
  color: #2b2d31;
}
.dark .color-card-drop {
  color: white;
}

.bg-color-action {
  background-color: var(--color-action);
}
.bg-color-danger {
  background-color: var(--color-danger);
}

/* Focus styles START */
.hoveringfocus:not(:focus):not(:focus-within):not(:focus-visible):not(:has(:focus-visible)):hover {
  border-color: var(--color-primary);
}

.visualngfocus,
.ngfocus:focus, /* main=root, focus=any */
.ngfocus:focus-within, /* main=nested, focus=any */
.ngfocus:focus-visible, /* main=root, focus=keyboard */
.ngfocus:has(:focus-visible) /* main=nested, focus=keyboard */ /* There was "> *" */ {
  position: relative;
}

.visualngfocus,
.ngfocus:focus,
.ngfocus:focus-within,
.ngfocus:focus-visible,
.ngfocus:has(:focus-visible),
.ngfocus:focus *,
.ngfocus:focus-within *,
.ngfocus:focus-visible *,
.ngfocus:has(:focus-visible) * {
  outline: none;
}

.visualngfocus::before,
.ngfocus:focus-visible::before,
.ngfocus:has(:focus-visible)::before {
  --offset: 2px;

  position: absolute;
  top: calc(-1px - var(--offset) - var(--offset));
  right: calc(-1px - var(--offset) - var(--offset));
  bottom: calc(-1px - var(--offset) - var(--offset));
  left: calc(-1px - var(--offset) - var(--offset));
  border: 2px solid var(--color-blue-300);
  z-index: inherit;
  border-radius: calc(var(--border-radius) + var(--offset) + var(--offset));
  content: "";
}

/* Focus styles END */

.nginnerbutton {
  position: relative;
}
.nginnerbutton:disabled {
  cursor: not-allowed;
}
.nginnerbutton:not(:disabled):hover::before {
  position: absolute;
  z-index: inherit;

  --size: 1.5rem;
  left: calc(50% - calc(var(--size) / 2));
  top: calc(50% - calc(var(--size) / 2));
  width: var(--size);
  height: var(--size);

  background-color: var(--color-blue-300);
  opacity: 0.15;
  border-radius: 100%;
  content: "";
}

/* Form spacings */
.ngformbuttons {
  margin-top: 2rem;
}
/* Form spacings END */

/* Related to table */

.table-header {
  position: sticky;
}

@media only screen and (max-width: 1400px) {
  .table-header {
    position: static;
  }
}

/* Detail page */

.n-detail-nav-parent {
  width: 200px;
}

.n-detail-content {
  margin-top: 32px;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 900px;
}

.n-detail-content:has(.auth-content) {
  margin-top: 0;
  padding: 32px 16px;
  background-color: rgba(245, 245, 245, 1);
}
.dark .n-detail-content:has(.auth-content) {
  background-color: rgb(18, 23, 30);
}
.auth-content-card {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: white;
}
.dark .auth-content-card {
  background-color: var(--bg-color);
}

.account-create .n-detail-content {
  min-width: unset;
}

@media only screen and (max-width: 1400px) {
  .n-detail-content {
    padding-left: 0px;
    margin-top: 10px;
  }
}

/* New Detail nav */

.n-detail-nav-parent svg {
  width: 16px;
  height: 16px;
}

/* New Detail nav END */

.table-title-visible {
  position: static;
}

.table-title-invisible {
  position: fixed;
  top: 100px;
  color: white;
}

#gvcChange,
#orgChange,
#accountChange {
  position: absolute;
  top: 25%;
  left: 270px;
  min-width: 233px;
  max-width: 540px;
  max-height: 600px;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
  z-index: 2;
}

/* Footer */
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: fit-content;
  padding: 0.625rem /* 10px */ 1.25rem /* 20px */;
  border-top: 1px solid var(--color-border);
  background-color: var(--color-drop);

  /* Text Styles */
  color: var(--color-gray-600);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
}

.dark .footer {
  color: var(--color-gray-300);
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* 4px */
}

.footer-links {
  display: flex;
  gap: 2.75rem; /* 44px */
}

.query-modal {
  background-color: var(--color-drop);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);

  transform: translateY(calc(34px + 50%));
  right: 20px;
  width: 800px;
}

/* Start of generic table classes */
/* Only used by volumeset volumes table */
.volumes-table * {
  border-color: var(--color-border);
}
.table-labels {
  background-color: var(--color-table-label-drop);
  font-weight: 500;
  border-radius: 6px;
}

.table-labels .nglabel,
.table-labels .ngdescription {
  color: var(--color-table-label);
}
/* End of generic table classes */

/* 'lg': '1280px', */
@media (max-width: 1280px) {
  /* .testing-breakpoints {
    background-color: orange;
  } */
}

/* 'md': '1024px', */
@media (max-width: 1024px) {
  /* .testing-breakpoints {
    background-color: yellow;
  } */

  .footer {
    flex-direction: column-reverse;
    align-items: unset;
    justify-content: unset;
  }

  .footer-copyright {
    margin-top: 1rem;
  }

  .footer-copyright > * {
    line-height: 1;
  }

  .footer-links {
    justify-content: space-between;
    gap: 0.5rem;
  }

  .account-create .ngformelement-error {
    width: unset !important;
  }
  .account-create .ant-select {
    width: 100% !important;
  }
}

/* 'sm': '768px', */
@media (max-width: 768px) {
  /* .testing-breakpoints {
    background-color: blue;
  } */
}

/* 'xs': '640px', */
@media (max-width: 640px) {
  /* .testing-breakpoints {
    background-color: aqua;
  } */
}

.image-info-box {
  background-color: var(--color-gray-50);
}

.dark .image-info-box {
  background-color: var(--color-gray-1400);
}
