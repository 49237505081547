.marketplace .markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    font-size: 28pt;
  }

  h2 {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--color-border);
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    background-color: inherit;
    font-size: 14px;
  }

  hr {
    height: 0.2em;
    border: 0;
    color: var(--color-border);
    background-color: var(--color-border);
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  li,
  table,
  pre {
    margin: 15px 0;
  }

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid var(--color-border);

    border-radius: 3px;
    padding: 5px;
  }

  tr:nth-child(even) {
    background-color: var(--color-border);
  }

  a,
  a:visited {
    color: #4183c4;
    background-color: inherit;
    text-decoration: none;
  }

  #message {
    border-radius: 6px;
    border: 1px solid var(--color-border);
    display: block;
    width: 100%;
    height: 60px;
    margin: 6px 0px;
  }

  button,
  #ws {
    font-size: 10pt;
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid var(--color-border);
    background-color: var(--color-border);
  }

  code,
  pre,
  #ws,
  #message {
    font-family: Monaco, monospace;
    font-size: 10pt;
    border-radius: 3px;
    background-color: var(--color-border);
    color: inherit;
  }

  code {
    border: 1px solid var(--color-border);
    margin: 0 2px;
    padding: 0 5px;
  }

  pre {
    border: 1px solid var(--color-border);
    overflow: auto;
    padding: 4px 8px;
  }

  pre > code {
    border: 0;
    margin: 0;
    padding: 0;
  }

  #ws {
    background-color: var(--color-border);
  }

  .send {
    color: #77bb77;
  }
  .server {
    color: #7799bb;
  }
  .error {
    color: #aa0000;
  }
}

.dark .marketplace .markdown {
}
