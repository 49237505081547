.inputGroup {
}

.inputGroup > div:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inputGroup > div:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.inputGroup > div:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.inputGroup > div:not(:last-of-type):not(:hover) {
  border-right-color: transparent;
}
.dark .inputGroup > div:not(:last-of-type):not(:hover) {
  border-left-color: transparent;
  border-right-color: var(--color-gray-1000);
}

.inputGroup.colSpan-first-1 > div:nth-child(1) {
  width: calc(100% / 12 * 1);
}
.inputGroup.colSpan-first-2 > div:nth-child(1) {
  width: calc(100% / 12 * 2);
}
.inputGroup.colSpan-first-3 > div:nth-child(1) {
  width: calc(100% / 12 * 3);
}
.inputGroup.colSpan-first-4 > div:nth-child(1) {
  width: calc(100% / 12 * 4);
}
.inputGroup.colSpan-first-5 > div:nth-child(1) {
  width: calc(100% / 12 * 5);
}
.inputGroup.colSpan-first-6 > div:nth-child(1) {
  width: calc(100% / 12 * 6);
}
.inputGroup.colSpan-first-7 > div:nth-child(1) {
  width: calc(100% / 12 * 7);
}
.inputGroup.colSpan-first-8 > div:nth-child(1) {
  width: calc(100% / 12 * 8);
}
.inputGroup.colSpan-first-9 > div:nth-child(1) {
  width: calc(100% / 12 * 9);
}
.inputGroup.colSpan-first-10 > div:nth-child(1) {
  width: calc(100% / 12 * 10);
}
.inputGroup.colSpan-first-11 > div:nth-child(1) {
  width: calc(100% / 12 * 11);
}
.inputGroup.colSpan-first-12 > div:nth-child(1) {
  width: calc(100% / 12 * 12);
}

.inputGroup.colSpan-second-1 > div:nth-child(2) {
  width: calc(100% / 12 * 1);
}
.inputGroup.colSpan-second-2 > div:nth-child(2) {
  width: calc(100% / 12 * 2);
}
.inputGroup.colSpan-second-3 > div:nth-child(2) {
  width: calc(100% / 12 * 3);
}
.inputGroup.colSpan-second-4 > div:nth-child(2) {
  width: calc(100% / 12 * 4);
}
.inputGroup.colSpan-second-5 > div:nth-child(2) {
  width: calc(100% / 12 * 5);
}
.inputGroup.colSpan-second-6 > div:nth-child(2) {
  width: calc(100% / 12 * 6);
}
.inputGroup.colSpan-second-7 > div:nth-child(2) {
  width: calc(100% / 12 * 7);
}
.inputGroup.colSpan-second-8 > div:nth-child(2) {
  width: calc(100% / 12 * 8);
}
.inputGroup.colSpan-second-9 > div:nth-child(2) {
  width: calc(100% / 12 * 9);
}
.inputGroup.colSpan-second-10 > div:nth-child(2) {
  width: calc(100% / 12 * 10);
}
.inputGroup.colSpan-second-11 > div:nth-child(2) {
  width: calc(100% / 12 * 11);
}
.inputGroup.colSpan-second-12 > div:nth-child(2) {
  width: calc(100% / 12 * 12);
}

.inputGroup.colSpan-third-1 > div:nth-child(3) {
  width: calc(100% / 12 * 1);
}
.inputGroup.colSpan-third-2 > div:nth-child(3) {
  width: calc(100% / 12 * 2);
}
.inputGroup.colSpan-third-3 > div:nth-child(3) {
  width: calc(100% / 12 * 3);
}
.inputGroup.colSpan-third-4 > div:nth-child(3) {
  width: calc(100% / 12 * 4);
}
.inputGroup.colSpan-third-5 > div:nth-child(3) {
  width: calc(100% / 12 * 5);
}
.inputGroup.colSpan-third-6 > div:nth-child(3) {
  width: calc(100% / 12 * 6);
}
.inputGroup.colSpan-third-7 > div:nth-child(3) {
  width: calc(100% / 12 * 7);
}
.inputGroup.colSpan-third-8 > div:nth-child(3) {
  width: calc(100% / 12 * 8);
}
.inputGroup.colSpan-third-9 > div:nth-child(3) {
  width: calc(100% / 12 * 9);
}
.inputGroup.colSpan-third-10 > div:nth-child(3) {
  width: calc(100% / 12 * 10);
}
.inputGroup.colSpan-third-11 > div:nth-child(3) {
  width: calc(100% / 12 * 11);
}
.inputGroup.colSpan-third-12 > div:nth-child(3) {
  width: calc(100% / 12 * 12);
}
