.drag-handle {
}

.drag-handle:hover {
  background-color: var(--color-gray-50);
}

.dark .drag-handle:hover {
  background-color: var(--color-gray-1300);
}
