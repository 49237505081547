// Root Block
.wrapper {
  background-color: #f7f8fa;
}
.dark-wrapper {
  background-color: #0d1117;
}

// Copy Button
.copy-button {
  color: white;
  transition: all 0.5s;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
