.create-link {
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;
  position: relative;
}

.create-link:first-of-type {
  margin-top: 0;
}

.create-link > .number {
  border: 1px solid var(--color-link);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 32px;
  height: 32px;
}

.create-link > .number > span,
.create-link > .content {
  color: var(--color-link);
  font-size: 16px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.create-link__active > .content {
  font-weight: 600;
}

.create-link__inactive {
  opacity: 0.75;
}

.create-link__active {
  opacity: 1;
}

.create-link__disabled,
.create-link__disabled:first-of-type {
  margin-top: 24px;
  cursor: not-allowed;
}

.create-link__disabled > .number > span,
.create-link__disabled > .content {
  color: var(--color-default);
}

.create-link__disabled > .number {
  border: 1px solid var(--color-default);
}

.create-link > .delete {
  position: absolute;
  right: 10px;
  color: var(--danger-500);
}

.create-link.push-top {
  margin-top: 24px;
}

.create-form {
  min-height: 575px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-form-header {
  font-size: 27.2px;
  color: var(--color-default);
  margin-bottom: 2rem;
  height: 35px;
}

.create-form-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-form-item,
.create-form-items {
  margin-top: 2rem;
}

.create-form-items > .create-form-item {
  margin-top: 0;
}

.create-nav-parent {
  padding-top: 2rem;
}

@media (max-width: 1024px) {
  .account-create .create-layout-buttons {
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-items: start;
    width: 100% !important;
  }
  .account-create .create-layout-buttons > * {
    width: 100% !important;
    margin-inline: 0 !important;
  }

  .account-create .create-form-header {
    /* font-size: 27.2px;
    color: var(--color-default);
    margin-bottom: 2rem;
    height: 35px; */
    min-height: unset;
    margin-bottom: unset;
  }

  .account-create .create-nav-parent {
    display: none;
  }
}
