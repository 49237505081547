:root {
  /* Colors */
  --color-white: #fff;
  --color-black: #000;

  --color-gray-50: #f4f4f4;
  --color-gray-100: #e8e8e8;
  --color-gray-200: #e3e3e3;
  --color-gray-300: #d5d5d5;
  --color-gray-400: #d2d2d2;
  --color-gray-500: #bfbfbf;
  --color-gray-600: #888694;
  --color-gray-700: #7c8086;
  --color-gray-800: #6c6b7b;
  --color-gray-900: #65646a;
  --color-gray-1000: #4c5155;
  --color-gray-1100: #47494f;
  --color-gray-1200: #47494f;
  --color-gray-1300: #323539;
  --color-gray-1400: #2a2d31;
  --color-gray-1500: #181c22;
  --color-gray-1600: #12171e;
  --color-gray-1700: #0c0f13;

  --color-blue-50: #e7f4ff;
  --color-blue-100: #b8deff;
  --color-blue-200: #2b99ff;
  --color-blue-300: #0084ff;
  --color-blue-400: #1563ff;
  --color-blue-500: #255dcb;
  --color-blue-600: #1646a4;
  --color-blue-700: #061c48;

  --color-yellow-50: #fefce8;
  --color-yellow-100: #fdeea0;
  --color-yellow-200: #ffb400;
  --color-yellow-300: #d19507;
  --color-yellow-400: #241b14;

  --color-green-50: #f0fdf4;
  --color-green-100: #c2fecc;
  --color-green-200: #34ec91;
  --color-green-300: #18dc7b;
  --color-green-400: #30bd80;
  --color-green-500: #03b869;
  --color-green-600: #029555;
  --color-green-700: #2b6534;
  --color-green-800: #0e2219;

  --color-red-50: #fef2f2;
  --color-red-100: #ffdada;
  --color-red-200: #ffc0c0;
  --color-red-300: #ff7469;
  --color-red-400: #ff4444;
  --color-red-500: #e62222;
  --color-red-600: #281717;
}
