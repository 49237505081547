/* Inner Nav */
.innernav {
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* 4px */
  padding-right: 2rem;
}

/* Inner Navlink */
.innernavlink {
  display: flex;
  flex-shrink: 0;
  gap: 0.375rem; /* 6px */
  padding: 0.375rem /* 6px */ 0.75rem /* 12px */;
  width: 100%;
  border-radius: var(--border-radius);
  transition: var(--transition);

  &:hover {
    background: var(--color-gray-100);
  }

  /* Selected state */
  &.selected {
    background: var(--color-gray-200);

    &:hover {
      background: var(--color-gray-200);
    }
  }

  /* Sub-item variant */
  &.sub {
    padding: 0.25rem /* 4px */ 1.75rem /* 28px */;
    padding-right: 0.75rem; /* 12px */
  }

  /* Green Action */
  &.action {
    color: var(--color-green-400);
  }
}

.dark .innernavlink {
  &:hover {
    background: var(--color-gray-1400);
  }

  /* Selected state */
  &.selected {
    background: var(--color-gray-1300);

    &:hover {
      background: var(--color-gray-1300);
    }
  }

  /* Green Action */
  &.action {
    color: var(--color-green-500);
  }
}
