/* SECTION Container */
.drawer-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 100001;

  &.fade-out {
    .drawer-backdrop {
      animation: fadeOut 0.3s ease-in-out forwards;
    }

    .drawer {
      animation: slideOut 0.3s forwards;
    }
  }
}

/* SECTION Background */
.drawer-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px); /* Add blur effect */
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

/* SECTION Drawer */
.drawer {
  width: 800px;
  background: var(--color-drop);
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateX(100%);
  animation: slideIn 0.3s forwards;

  &.slide-out {
    animation: slideOut 0.3s forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-title {
      margin: 0;
      font-size: 24px;
    }

    &-subtitle {
      margin: 0;
      font-size: 16px;
      color: var(--color-label);
    }
  }

  &-content {
    flex-grow: 1;
  }
}
