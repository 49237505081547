.header {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
}

.header-inputs {
  max-width: 600px;
}

.header-info {
  display: none;
}

@media (min-width: 1280px) {
  .header {
    grid-template-columns: 1fr 1fr;
  }

  .header-info {
    display: block;
  }

  .header-inputs {
    max-width: unset;
  }
}
