/* Sidebar */
.sidebar-container {
  display: flex;
  height: 100%;
  min-height: 100%;
  position: relative;
}
.sidebar-container .menu-list-divider {
  display: none;
}
.sidebar-container.collapsed .menu-list-divider {
  display: block;
}

.sidebar-scrollbar > div:first-of-type {
  margin-right: -60px !important;
}

.sidebar {
  padding: 20px;
  overflow-x: hidden;
}

.sidebar-console {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* 12px */
  width: fit-content;
}

/* Menu List */
.menu-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* 12px */

  /* Collapsed state */
  &.collapsed {
    h2 {
      display: none;
    }
  }

  h2 {
    display: block;
    color: var(--color-label);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.32px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem; /* 4px */
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: var(--color-border);
  }

  &-collapse-title {
    display: flex;
    align-items: center;
    gap: 0.625rem; /* 10px */
    cursor: pointer;
    fill: red;
    &:hover h2 {
      text-decoration: underline;
    }
  }
}

/* Menu Link */
.menu-link {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  padding: 10px;
  border-radius: var(--border-radius);
  transition: var(--transition);

  /* With selection option */
  &.with-arrow {
    border-radius: 0.375rem 0 0 0.375rem;
  }

  /* Selected state */
  &.selected {
    color: var(--color-white);
    background: var(--color-blue-300);

    .menu-link-icon,
    .menu-link-icon path {
      fill: var(--color-white);
    }

    span {
      color: var(--color-white);
    }

    &:hover {
      background: var(--color-blue-200);
    }

    /* With selection option */
    &.with-arrow {
      border-right: 1px solid var(--color-blue-200);
    }
  }

  &:hover {
    background: var(--color-gray-100);
  }

  span {
    color: var(--color-label-secondary);
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: var(--letter-spacing);

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu-link-icon {
    width: 20px;
    height: 20px;
  }

  .menu-link-icon,
  .menu-link-icon path {
    fill: var(--color-label-secondary);
  }
}

.dark .menu-link {
  /* Selected state */
  &.selected {
    background: var(--color-blue-600);

    &:hover {
      background: var(--color-blue-500);
    }

    /* With selection option */
    &.with-arrow {
      border-right: 1px solid var(--color-blue-500);
    }
  }

  &:hover {
    background: var(--color-gray-1400);
  }
}
