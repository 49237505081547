@media (max-width: 1024px) {
  .account-create .nginput {
    width: 100% !important;
  }

  .account-create .consent,
  .account-create .cc {
    width: 100% !important;
  }
}
