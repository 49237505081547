.ngbutton {
  --bg-color: var(--color-blue-300);
  --text-color: var(--color-white);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem; /* 6px */
  position: relative;
  width: fit-content;
  transition: var(--transition);

  border-radius: var(--border-radius);
  border: 1px solid var(--bg-color);

  color: var(--text-color);
  background-color: var(--bg-color);

  padding: 11px 10px;
  font-size: 1rem;
  line-height: var(--line-height-medium);

  svg {
    color: var(--text-color);
    stroke: var(--text-color);
  }
}

.ngbutton.disabled {
  cursor: not-allowed;
}

.ngbutton.normal {
  padding-block: 8px;
  line-height: var(--line-height-small);
}

.ngbutton.small {
  padding-block: 5px;
  font-size: 0.875rem;
  line-height: var(--line-height-small);
}

.ngbutton.primary {
  --bg-color: var(--color-blue-300);
  .dark & {
    --bg-color: var(--color-blue-600);
  }

  &:hover {
    --bg-color: var(--color-blue-400);

    .dark & {
      --bg-color: var(--color-blue-500);
    }
  }
}

.ngbutton.secondary {
  --bg-color: var(--color-gray-200);
  --text-color: var(--color-gray-900);

  .dark & {
    --bg-color: var(--color-gray-1000);
    --text-color: var(--color-white);
  }

  &:hover {
    --bg-color: var(--color-gray-500);

    .dark & {
      --bg-color: var(--color-gray-900);
    }
  }
}

.ngbutton.action {
  --bg-color: var(--color-green-300);

  .dark & {
    --bg-color: var(--color-green-600);
  }

  &:hover {
    --bg-color: var(--color-green-400);

    .dark & {
      --bg-color: var(--color-green-500);
    }
  }
}

.ngbutton.danger {
  --bg-color: var(--color-red-400);

  .dark & {
    --bg-color: var(--color-red-300);
  }

  &:hover {
    --bg-color: var(--color-red-500);

    .dark & {
      --bg-color: var(--color-red-400);
    }
  }
}

.ngbutton.outlined.ngbutton.outlined {
  --bg-color: transparent;
}

.ngbutton.primary.outlined {
  --text-color: var(--color-blue-300);
  border-color: var(--text-color);

  &:hover {
    --bg-color: var(--color-blue-50);

    .dark & {
      --bg-color: var(--color-blue-700);
      --text-color: var(--color-blue-300);
    }
  }
}

.ngbutton.secondary.outlined {
  --text-color: var(--color-gray-900);
  border-color: var(--text-color);

  .dark & {
    --text-color: var(--color-gray-400);
  }

  &:hover {
    --bg-color: var(--color-gray-200);
    --text-color: var(--color-gray-900);
    border-color: var(--text-color);

    .dark & {
      --bg-color: var(--color-gray-1300);
      --text-color: var(--color-gray-400);
    }
  }
}

.ngbutton.action.outlined {
  --text-color: var(--color-green-400);
  border-color: var(--text-color);

  &:hover {
    --bg-color: var(--color-green-100);
    --text-color: var(--color-green-400);
    border-color: var(--text-color);

    .dark & {
      --bg-color: var(--color-green-700);
      --text-color: var(--color-green-300);
    }
  }
}

.ngbutton.danger.outlined {
  --text-color: var(--color-red-400);
  border-color: var(--text-color);

  .dark & {
    --text-color: var(--color-red-300);
  }

  &:hover {
    --bg-color: var(--color-red-100);
    --text-color: var(--color-red-400);
    border-color: var(--text-color);

    .dark & {
      --bg-color: var(--color-red-600);
      --text-color: var(--color-red-300);
    }
  }
}

.ngbutton.text.ngbutton.text {
  border-color: transparent;
  --bg-color: transparent;
}

.ngbutton.primary.text {
  --text-color: var(--color-blue-300);

  &:hover {
    --text-color: var(--color-blue-400);
    --bg-color: var(--color-blue-50);

    .dark & {
      --bg-color: var(--color-blue-700);
      --text-color: var(--color-blue-300);
    }
  }
}

.ngbutton.secondary.text {
  --text-color: var(--color-gray-700);

  .dark & {
    --text-color: var(--color-gray-400);
  }

  &:hover {
    --text-color: var(--color-gray-800);
    --bg-color: var(--color-gray-50);

    .dark & {
      --bg-color: var(--color-gray-1300);
      --text-color: var(--color-gray-400);
    }
  }
}

.ngbutton.action.text {
  --text-color: var(--color-green-400);

  &:hover {
    --text-color: var(--color-green-500);
    --bg-color: var(--color-green-50);

    .dark & {
      --bg-color: var(--color-green-700);
      --text-color: var(--color-green-300);
    }
  }
}

.ngbutton.danger.text {
  --text-color: var(--color-red-400);

  .dark & {
    --text-color: var(--color-red-300);
  }

  &:hover {
    --text-color: var(--color-red-500);
    --bg-color: var(--color-red-50);

    .dark & {
      --bg-color: var(--color-red-600);
      --text-color: var(--color-red-300);
    }
  }
}

.ngbutton.disabled.ngbutton.disabled {
  --bg-color: var(--color-gray-100);
  --text-color: var(--color-gray-500);
  border-color: var(--bg-color);

  .dark & {
    --bg-color: var(--color-gray-1400);
    --text-color: var(--color-gray-900);
  }
}
