.error-page {
  display: flex;
  padding: 36px;
  justify-content: center;
  align-items: center;
  gap: 96px;
  flex: 1 0 0;
  align-self: stretch;

  &-header {
    display: flex;
    width: 100%;
    background-color: var(--color-drop);
    border-bottom: 1px solid var(--color-border);
    padding: 10px 20px 10px 28px;
    justify-content: space-between;
    align-items: center;

    img {
      height: 49px;
      cursor: pointer;
    }
  }
  &-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: var(--color-drop);
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 700px;

    &-error-code {
      color: var(--color-label-secondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &-error-title {
      color: var(--color-blue-300);
      text-decoration-line: underline;
    }
    &-title {
      color: var(--color-label);
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &-message {
      color: var(--color-label);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &-error-image {
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;

    /* Text Styles */
    color: var(--color-blue-300);
    text-align: center;
    font-size: 160px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -3.2px;

    .colored {
      margin: 0 -20px;
      color: var(--color-blue-100);
    }
  }
}

.dark .error-page {
  &-container {
    background-color: var(--color-gray-1500);
  }
  &-content-error-title {
    color: var(--color-blue-500);
  }
  &-error-image {
    color: var(--color-blue-600);

    .colored {
      color: var(--color-blue-500);
    }
  }
}
