.table-container {
  overflow-x: auto;
}

.table-container table {
  padding-bottom: 0.25rem;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 6px;
  table-layout: fixed;
}

.table-container table:has(.infobox) {
  min-height: 10rem;
}

.table-container table thead > tr > th:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table-container table thead > tr > th:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.table-container table thead + thead {
  margin-top: 2px;
}

.table-container table th {
  font-weight: 500;
}
.table-container table tr td {
  border-bottom: 1px solid var(--color-border);
}

.table-container table .header-separator {
  height: 20px;
  width: 1px;
}

.table-container table th:last-of-type .header-separator:not(.resize-handler) {
  display: none;
}

.table-container table .header-separator.resize-handler {
  margin-left: 6px;
  width: 10px;
  user-select: none;
  touch-action: none;
  cursor: col-resize;
}

.table-container table .header-separator.resize-handler:hover svg {
  color: var(--color-gray-800);
}
.dark .table-container table .header-separator.resize-handler:hover svg {
  color: var(--color-gray-500);
}

.table-container table tr {
  position: relative;
}

.table-container table th {
  background-color: var(--color-gray-200);
}
.dark .table-container table th {
  background-color: var(--color-gray-1400);
}

.table-container table tr.row-group-label th {
  background-color: var(--color-gray-50);
}
.dark .table-container table tr.row-group-label th {
  background-color: var(--color-gray-1300);
}

.table-container table tr.item-row:hover {
  background-color: rgb(248, 248, 248, 1);
}
.dark .table-container table tr.item-row:hover {
  background-color: var(--color-gray-1600);
}

/* paddings */
/* data cells - horizontal */
.table-container table td {
  padding-left: 1rem;
  padding-right: 0.5rem;
}
/* data cells - vertical */
.table-container table tr td {
  padding-block: 0.25rem;
}
/* header cells vertical */
.table-container table th {
  padding-block: 0.5rem;
}
/* fix 1px right padding on all th except the last one */
.table-container table tr th:not(:last-of-type) {
  padding-right: 0;
}
/* fix 1px left padding on all th except the first one */
.table-container table tr th:not(:first-of-type) {
  padding-left: 0;
}
/* header cells child, only left */
.table-container table th:not(:first-of-type) > * {
  padding-left: 1rem;
}
/* first and last cells */
.table-container table tr th:first-of-type,
.table-container table tr td:first-of-type {
  padding-left: 1rem;
}
.table-container table tr.row-group-label th {
  padding-left: 0.75rem;
}
.table-container table tr th:last-of-type,
.table-container table tr td:last-of-type {
  padding-right: 1rem;
}
/* // padding end */

.table-container table td,
.table-container table td * {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.table-container .query-before::before {
  content: "";
  height: 15px;
  width: 15px;
  border-right: 2px solid rgb(243, 243, 243);
  border-top: 2px solid rgb(243, 243, 243);
  position: absolute;
  transform: translateY(calc(26px + 50%)) rotateZ(-45deg);
  background-color: white;
  z-index: 2;
}

.table-container .dark .query-before::before {
  background-color: var(--color-drop);
  border-color: var(--color-border);
}

.table-container .description {
  color: var(--color-gray-600);
}

.dark .table-container .description {
  color: var(--color-gray-400);
}
