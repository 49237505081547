.ngcheckbox {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-label);

  &:hover {
    .box-inner {
      border: 1px solid var(--color-blue-200);
    }
  }

  &.disabled:hover .box-inner,
  &.notAllowed:hover .box-inner {
    border: 1px solid var(--color-border);
  }

  &.disabled {
    opacity: 0.5;
  }

  &.notAllowed {
    cursor: not-allowed;
  }

  .box {
    position: relative;
  }

  .box-inner {
    width: 1.1875rem;
    height: 1.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid var(--color-gray-200);
    border-radius: 0.25rem;
    color: var(--color-white);
    transition: all 400ms;

    > svg {
      stroke: var(--color-white);
      width: 12px;
      height: 12px;

      > polyline {
        transition: "all 400ms";
      }
    }

    &.disabled {
      border-color: var(--color-border);
    }

    &.checked {
      background: var(--color-blue-200);
      border-color: var(--color-blue-200);

      &:hover {
        background: var(--color-blue-500);
        border-color: var(--color-blue-500);
      }
    }

    &.indeterminate {
      background: var(--color-blue-200);
      border-color: var(--color-blue-200);
    }
  }

  .dark & {
    .box-inner {
      border-color: var(--color-gray-400);
    }

    .box-inner.checked,
    .box-inner.indeterminate {
      border-color: var(--color-blue-200);
    }

    &:hover {
      .box-inner {
        border-color: var(--color-blue-200);
      }
    }

    &.disabled {
      &:hover {
        .box-inner {
          border-color: var(--color-gray-400);
        }
        .box-inner.checked,
        .box-inner.indeterminate {
          border-color: var(--color-blue-200);
        }
      }
    }
  }

  input:hover::before {
    position: absolute;
    z-index: inherit;

    --size: 2rem;
    left: calc(50% - calc(var(--size) / 2));
    top: calc(50% - calc(var(--size) / 2));
    width: var(--size);
    height: var(--size);

    background-color: var(--color-blue-300);
    opacity: 0.15;
    border-radius: 100%;
    content: "";
  }
}
