.ngnavitemgroup {
  background-color: rgba(0, 0, 0, 0.025);
  .dark & {
    background-color: rgba(255, 255, 255, 0.025);
  }
}

.ngnavitem {
}

.ngnavitem:hover {
  background-color: var(--color-gray-50);
  .dark & {
    background-color: var(--color-gray-1300);
  }
}

.ngnavitem.active {
  background-color: var(--color-drop-secondary);
  &:hover {
    background-color: var(--color-drop-secondary);
  }
}

.ngnavitem.action {
  color: var(--color-action);
}

.ngnavitem.danger {
  color: var(--color-danger);
}

.ngnavitem.disabled {
  background-color: transparent;
  &:hover,
  &:active {
    background-color: transparent;
  }

  color: var(--color-gray-300);
  cursor: not-allowed;

  .dark & {
    color: var(--color-gray-1100);
  }
}
