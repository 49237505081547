.nginput {
  --color: var(--color-input);
  --bg-color: var(--color-input-bg);
  --border-color: var(--color-input-border);
  --icon-width: 1.25em;

  position: relative;
  border-radius: var(--border-radius);
  border-width: 1px;
  border-color: var(--border-color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  padding-right: 10px;

  &.disabled {
    --bg-color: var(--color-gray-100);
    --color: var(--color-gray-500);

    input {
      cursor: not-allowed;
    }

    &:hover,
    &:active {
      --bg-color: var(--color-gray-100);
      --color: var(--color-gray-500);
    }
  }

  &.invalid {
    --border-color: var(--color-danger);
  }
  &.warning:not(.invalid) {
    --border-color: var(--color-warning);
  }
}

.dark .nginput {
  &.disabled {
    --bg-color: var(--color-gray-1400);
    --color: var(--color-gray-900);

    input {
      cursor: not-allowed;
    }

    &:hover,
    &:active {
      --bg-color: var(--color-gray-1400);
    }
  }
}

.nginput > input {
  font-size: 1rem;
  width: 100%;
  position: relative;
  padding-left: 10px;
  border-radius: 6px;
  color: var(--color);
  background-color: transparent;
}

.nginput > input.withButtons {
  padding-right: 10px;
}

.nginput.large > input {
  padding-block: 8.5px;
}

.nginput.normal > input {
  padding-block: 4.5px;
}

.nginput.small > input {
  padding-block: 1.5px;
}

.nginput > input::placeholder {
  color: var(--color-placeholder);
}

/* Copied from ngselect css */
/* TODO generalize this css */
/* Showing empty characters */
.nginput .clone {
  pointer-events: none;
  position: absolute;
  inset: 0;
  padding-block: calc(0.6rem - 1px);
  padding-left: 1.2rem;
  font-size: 1rem;
  font-family: "proxima-nova", sans-serif;
  display: flex;
}

.nginput .clone > .startsWith,
.nginput .clone > .endsWith {
  display: flex;
  height: 100%;
}

/* TODO get width of each character and render them transparent? */

.nginput .clone .emphasizeIndicator {
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
}
.dark .nginput .clone .emphasizeIndicator {
  background-color: rgba(255, 255, 255, 0.15);
  height: 100%;
}

.nginput .width-calculator {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
