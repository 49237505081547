.circular-theme-switch-container {
  height: 23px;
}

#theme-toggle {
  visibility: hidden;
}

#theme-toggle + label {
  content: "";
  display: inline-block;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

#theme-toggle:not(:checked) + label {
  background-color: gold;
}

#theme-toggle:checked + label {
  background-color: transparent;
  box-shadow: inset -6px -5px 1px 1px #fff;
}
