.release-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1rem;
}

.release-card-name {
  transition: color 0.15s;
}
.release-card:hover .release-card-name {
  color: var(--color-primary);
}
