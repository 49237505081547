/* Link With Change */
.link-with-change {
  position: relative;
  display: flex;
}

.link-with-change-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 0 0.375rem /* 6px */ 0.375rem /* 6px */ 0;
  transition: var(--transition);
  color: var(--color-label-secondary);

  &:focus {
    outline: 2px solid var(--color-blue-300);
  }

  /* Collapsed state */
  &.collapsed {
    position: absolute;
    padding: 10px 0;
    right: -20px;
    border-radius: 0;
  }

  /* Selected state */
  &.selected {
    background: var(--color-blue-300);
    color: var(--color-white);

    &:hover {
      background: var(--color-blue-200);
    }
  }

  &:hover {
    background: var(--color-gray-100);
  }
}

.dark .link-with-change-arrow {
  /* Selected state */
  &.selected {
    background: var(--color-blue-600);

    &:hover {
      background: var(--color-blue-500);
    }
  }

  &:hover {
    background: var(--color-gray-1400);
  }
}
