.marketingSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 53px;
  width: 489px;
  background: linear-gradient(180deg, #1c2350 0%, #070a1f 100%);
  color: white;
}

.items {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  max-height: 800px;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
}

.item {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  position: relative;

  & > *:nth-child(1) {
    position: absolute;
    left: 4px;
    top: 6px;
    width: 8px;
    height: 8px;
    background-color: white;
  }

  & > span {
    width: 14px;
    height: 14px;
    transform: translateY(3px);
    & * {
      color: #1564ff;
    }
  }

  & > *:nth-child(3) {
    color: #c1c1d8;
  }
}

.divider {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #7ca8ff3d;
}

.quote {
  padding-block: 66px;
}

.text {
  font-size: 27px;
  line-height: 36px;
}

.owner {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 36px;
  & > img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin-right: 10px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    line-height: 24px;
    font-size: 16px;
    & > div:nth-child(1) {
      font-weight: 800;
      letter-spacing: 2px;
    }
    & > div:nth-child(2) {
      color: #7ca8ff;
    }
  }
}

.globe {
  position: absolute;
  inset: 0;
  top: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > img {
    object-fit: contain;
    width: 410px;
  }
}
